.AddStock {
    text-align: center;
    width: 90%;
    margin: auto;
  }
  .AddStock h1{
      margin-bottom: 20px;
      color: #485468 !important;
  }

  .AddStock h2{
    color: #ee121d !important;
      margin-bottom: 20px
  }

  
  .Cards {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Table {
    margin-bottom: 20px;
  }
  
  .RTswitch{
      margin-top: 20px;
      margin-bottom: -25px;
      display: flex;
      align-items: center;
  }
  .RTswitch p{
      font-weight: bold
  }