.Cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.RadioButtonList {
  display: flex;
  justify-content: space-evenly;
  margin: 30px auto;
  flex-wrap: wrap;
}
.statusOptions h2 {
  text-align: center;
}
.emptyState {
  margin-top: 50px;
  text-align: center;
}
.emptyState img {
  height: 100px;
}
.InvoicesCards {
  text-align: center;
}
.InvoicesCard {
  padding: 0px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.number {
  margin: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #01acc1;
  color: white;
  border-radius: 25px;
  width: 40px;
  height: 40px;
  font-size: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.Button{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

}